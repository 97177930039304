*{
    padding: 0;
    margin: 0;
}
body{
    background-color: #eaeded;
}

.underline:hover{
    text-decoration: underline!important;
}

header.masthead {
    position: relative;
    margin-bottom: 3rem;
    padding-top: calc(8rem + 57px);
    padding-bottom: 8rem;
    background: no-repeat center center;
    background-color: #6c757d;
    background-size: cover;
    background-attachment: scroll;
  }
  header.masthead:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212529;
    opacity: 0.5;
  }
  header.masthead .page-heading h1, header.masthead .page-heading .h1 {
    font-size: 3rem;
    font-weight: 800;
  }

  .veiw-h{
    min-height: 100vh;
  }